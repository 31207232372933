.inputContainer {
  position: relative;
  margin: 15px 0 10px;

  input {
    width: 100%;
    line-height: 30px;
    font-size: 14px;
    border: none;
    border-bottom: 1px solid lightgray;

    &::placeholder {
      color: lightgray;
      font-size: 14px;
    }

    &:-ms-input-placeholder {
      color: lightgray;
      font-size: 14px;
    }

    &::-ms-input-placeholder {
      color: lightgray;
      font-size: 14px;
    }
  }

  .toggleIcon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
  }
}
