@import '../../styles/colors';

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $background-color;
  color: $text-color;
  overflow-y: scroll;
}

.innerContainer {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.mainContent {
  flex-grow: 1;
  padding: 0 20px;
  margin-top: 10px;
}
