$header-padding: 20px 20px 10px;
$color-header: #333;
$button-padding: 10px 20px;
$button-bg-color: #5b9bd4;
$button-hover-bg-color: #666;
$border-radius: 4px;

.companyTableHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $header-padding;
}
.heading {
  color: $button-bg-color;
}

.addCompanyButton {
    padding: $button-padding;
    background-color: $button-bg-color;
    color: white;
    border: none;
    border-radius: $border-radius;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: bold;

    &:hover {
        background-color: $button-hover-bg-color;
    }

    &:focus {
        outline: none;
        // Consider adding a custom style for focus to maintain accessibility
    }

    &:active {
        transform: translateY(1px);
    }
}
