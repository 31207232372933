$bg-color: #5b9bd4;
$disabled-color: #ccc;
$button-hover-bg-color: #666;

.form {
    display: flex;
    flex-direction: column;
    max-width: 400px;
  }
  
  .formGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    &[hidden] {
      margin: 0;
      padding: 0;
      display: none;
    }
  }
  
  label {
    margin: 5px 10px 0px;
    color: grey;
    font-size: x-small;
  }  
  
  input {
    padding: 3px 0px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin:0px 10px;
  
    &:focus {
      outline: none;
      background-color: none;
      border-color: $bg-color;
    }
    &[type="text"],
    &[type="password"],
    &[type="date"] {
      border-width: 0 0 1px;
      border-radius: 0;
    }

    &[type="number"] {
      border-width: 0 0 1px;
      border-radius: 0;
      max-width: 60px;
    }

    &[type="radio"] {
      margin: 10px;
    }

  }
  
  .checkbox{
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 5px 0px 5px 10px;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    outline: none;

    &:not(:checked) + .togglable {
      color: $disabled-color;
      input {
        pointer-events:none;
        color: $disabled-color;
      }
    }
    &:checked { 
      background-color: $bg-color;
    }
    &:before {
      content: "\2713"; /* Unicode character for the tick symbol */
      position: relative;
      top: -4px;
      left: 3px;
      font-size: 12px;
      font-weight: 700;
      color: #fff;
      visibility: hidden;
    }
    &:checked::before {
      visibility: visible;
    }
  }

  fieldset {
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .radioLabel {
    margin: 0px;
    color: black;
    font-size: medium;

    &:has(input[type='radio']:disabled) {
      color: $disabled-color;
    }
  }

  .radioTile {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-auto-rows: 1fr;
    gap: 16px;
    padding: 10px;
  }
  
  .radioTile label {
    display: flex;
    align-items: stretch;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: white;
    min-height: 55px;
    padding: 16px;
    border-radius: 3px;
    border: 1px solid #d4d4d4;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .radioTile input {
    display: none;
  }
  
  .radioTile input:checked ~ label {
    background-color: #cadced;
    border: 2px solid #5b9bd4;
    // box-shadow: 0px 0px px 0px #8e8e8e;
    // color: #5b9bd4;
  }
  
  .radioTile label:hover {
    background-color: #e0e0e0;
  }


  .inputNumberUnit {
    font-size: small;
  }

  .leftUnit {
    margin-left: 10px;
  }

  .togglableGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  select {
    padding: 5px 0px;
    margin: 3px 6px;
    border: 0px;
    outline: 0px;
    // text-indent: 5px;
    &:focus {
      border: 1px;
    }
  }
  
  .error {
    border-color: red;
  }
  
  .errorMessage {
    color: red;
    font-size: xx-small;
    text-align: end;
    margin:0px 10px 5px;
  }

  .formButton {
      padding: 10px;
      border: none;
      background-color: transparent;
      color: $bg-color;
      border-radius: 0px;
      cursor: pointer;
      white-space: nowrap;
  }
  .buttonContainer {
    display: flex;
    justify-content: space-between; /* Adjust as needed for alignment */
    gap: 130px; /* Space between buttons */
  }
  .buttonRightContainer {
    display: flex;
    margin-left: auto;
    gap: 10px;
  }

  .customSelect select {
    padding-left: 5px;
    width: 96%;
    border-bottom: 1px solid #ccc;
    appearance: none;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="10" viewBox="0 0 320 512"><!--!Font Awesome Free 6.5.1 by @fontawesome- https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" fill="%235b9bd4"/></svg>');
    background-repeat: no-repeat; 
    background-position: right;
  }
  .customSelect select:focus {
    border-bottom: 1px solid $bg-color; /* Same border style as when not focused */
    outline: none;
  }