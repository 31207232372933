.connectContainer {
  // width: 90vw;
  // height: 12vh;
  border: 1px solid grey;
  margin: 30px;
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;

  button {
    width: 148px;
  }
}

.connectInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;

  span {
    font-weight: 700;
  }

  img {
    height: 50px;
    width: 50px;
  }

  a {
    margin-left: 5px;
    text-decoration: none;
    color: rgb(27, 74, 204);
  }
}

.note {
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  padding: 0px 10px;
  font-size: small;
}

.tooltip {
  position: relative;
  display: inline-block;
  // border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

.icon {
  cursor: help;
}

.tooltip .tooltipText {
  visibility: hidden;
  min-width: 170px;
  background-color: rgb(36, 36, 36);
  color: white;
  text-align: left;
  font-weight: normal;
  font-size: small;
  padding: 5px 8px;
  white-space: pre-wrap;
  border-radius: 6px;
 
  position: absolute;
  z-index: 1;
  // top: 120%;
  left: 150%;
}

.tooltip:hover .tooltipText {
  visibility: visible;
}

.error {
  color: red;
}

.success {
  color: green;
}
