.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: white;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 90%;
  max-width: 450px;
}

.modalTitle {
  color: #5b9bd4;
  margin: 10px 10px 25px;
};

.modalDescription {
  margin: 10px 10px 20px;
};

.disabled {
  pointer-events: none;
}
