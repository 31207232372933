
@import '../../styles/colors';

.mainContainer {
	padding: 20px 50px;
	display: flex;
	flex-direction: row;
  overflow: auto;
  box-sizing: border-box;
}

.stepsContainer {
	display: flex;
	flex-direction: column;
}

.stepContent {
	display: flex;
	margin: 2px 0px;
  align-items: center;
  // flex-direction: column; // Stack items vertically
  // margin: 2px 0px;
}

.clickable {
  cursor: pointer;
}

.stepLabel {
	margin: 0px 10px;
	color: $text-color-light;
  white-space: nowrap;
}

.stepIcon {
	font-size: 20px;
	color: #474c52;
}

.stepLine {
	height: 18px;
	width: 2px;
	background-color: $divider-color;
	margin: 0 9px;
	border-radius: 10px;
}

.flexContainer {
	display: flex;
}

.contentContainer {
	margin-top: 20px;
	gap: 80px;
	display: flex;
	width: 100%;
}

.buttonContainer {
	display: flex;
  margin-left: auto;
  gap: 10px;
  justify-content: end;
}

.primaryButton {
  background-color: $primary-color;
  color: white;
  border-radius: 20px;
  padding: 6px 20px;
  border: none;
  transition: filter 200ms;
  
  &:hover {
    filter: brightness(0.85);
  }
}

.secondaryButton {
  color: $primary-color;
  border: none;
  border-radius: 20px;
  padding: 6px 20px;
  background-color: $background-color;
  border: 1px solid $primary-color;
  transition: filter 200ms;

  &:hover {
    filter: brightness(0.85);
  }
}

.formContainer {
  flex: 0.85;
	// width: 100%;
	font-size: medium;
}

.disabled {
  pointer-events: none;
}

.campaignControl {
  padding: 5px 5px 10px;
  margin-bottom: 5px;
  border-radius: 10px;
  // max-width: 600px;
  transition: background-color 200ms;
  // border: 1px solid $divider-color;
  &:hover {
    background-color: #eaeaea;
  }
}

.switchContainer {
  justify-self: flex-end;
  margin-left: auto;
}

.contentHeader {
  display: flex;
  align-items: center;
  // padding: 0px 0px 2px;
  border-bottom: 1px solid $divider-color;
  margin-bottom: 10px;
}

.content {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
  width: 100%;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  gap: 10px;
}

.numberContainer {
  width: 60px;
}

.longNumberContainer {
  width: 85px;
}

.targetDatesContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}

.targetDate {
  width: 200px;
}

.completedIcon {
  color: $primary-color;
}

.currentIcon {
  color: $primary-color;
}

.notCompletedIcon {
  color: $primary-color;
}

.error {
  font-size: small;
  color: $error;
}

.formContent {
  margin: 10px 0px;

	label {
		font-size: medium;
		font-weight: bold;
    margin: 2px 0px;
	}

  input {
    font-size: medium;
    padding: 3px 0px;
    border: 1px solid $divider-color;
    border-radius: 5px;
    margin: 0px;
    padding: 6px 8px;

    &:focus {
      // outline: none;
      // background-color: none;
      border-color: $primary-color !important;
    }
    &::placeholder {
      color: #adadad;
      font-size: 14px;
    }
    &[type="text"],
    &[type="password"],
    &[type="date"] {
      border: 1px solid $divider-color;
      border-radius: 5px;
      width: 100%;
    }

    &[type="number"] {
      border: 1px solid $divider-color;
      border-radius: 5px;
      // width: min-content;
      width: 100%;
      max-width: none;
    }
  }

  select {
    font-size: medium;
    padding: 8px;
    border: 1px solid $divider-color;
    border-radius: 5px;
    // text-indent: 5px;
    &:focus {
      border: 1px solid $primary-color !important;
    }
    // padding-left: 5px;
    width: 100%;
    background: none;
    // border-bottom: 1px solid $divider-color;
    // appearance: none;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="10" viewBox="0 0 320 512"><!--!Font Awesome Free 6.5.1 by @fontawesome- https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" fill="%235b9bd4"/></svg>');
    background-repeat: no-repeat; 
    background-position: calc(100% - 8px) center;;
  }
}
